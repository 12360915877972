import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Products.scss';
import { TextField, Collapse, Button, Select, MenuItem, } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { deleteProductDataAction, getAllProductsData, getAllUnitData, setNewProductDataAction, updateProductDataAction } from '../../redux/actions/productActions';
import DynamicTable from '../../components/table/Table';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import { scrollTop } from '../../services/utils';


const Products = () => {
  const dispatch = useDispatch();
  const [isAddNewFormVisible, setAddNewFormVisible] = useState(false);
  const { productsData, unitData } = useSelector(state => state.productsData);
  const [productData, setProductData] = useState([]);
  const [unitsData, setUnitsData] = useState([]);
  const [shouldResetForm, setShouldResetForm] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editData, setEditData] = useState({});

  const handleAddNewForm = () => {
    setAddNewFormVisible(!isAddNewFormVisible);
  };

  useEffect(() => {
    if (!productsData) {
      dispatch(getAllProductsData());
    } else {
      setProductData(productsData);
    }
  }, [productsData]);

  useEffect(() => {
    if (!unitData) {
      dispatch(getAllUnitData());
    } else {
      setUnitsData(unitData);
    }
  }, [unitData]);

  const formAddNewProduct = useFormik({
    key: shouldResetForm ? 'resetKey' : null,
    initialValues: {
      id: '',
      name: '',
      price: '',
      unit: ''
    },
    validationSchema: Yup.object({
      id: Yup.string().required('Mã SP không được để trống'),
      name: Yup.string().required('Tên SP không được để trống'),
      price: Yup.number().min(1000, 'Đơn giá phải lớn hơn hoặc bằng 1000'),
      unit: Yup.string().required('Đơn vị không được để trống'),
    }),
    onSubmit: async (order) => {
      if (!isEditMode) {
        try {
          const result = await dispatch(setNewProductDataAction(order));
          console.log(result.success);
          if (!result.success) {
            formAddNewProduct.setFieldError('id', 'Mã SP đã tồn tại');
            setShouldResetForm(false);
          } else {
            dispatch(getAllProductsData())
            setShouldResetForm(true);
          }
        } catch (error) {
          setShouldResetForm(false);
        }
      } else {
        try {
          const res = await dispatch(updateProductDataAction(order));
          if(res.success){
            formAddNewProduct.resetForm({ values: formAddNewProduct.initialValues });
            setIsEditMode(false);
          }
        } catch (error) {
          console.error(error)
        }
      }
    },
  });

  useEffect(() => {
    if (isEditMode) {
      formAddNewProduct.setValues(editData);
    }
  }, [editData, isEditMode])

  const productsColumns = [
    { id: 'id', label: 'Mã SP' },
    { id: 'name', label: 'Tên SP' },
    { id: 'price', label: 'Giá bán', isFormatNumber: true },
    { id: 'unit', label: 'Đơn vị' },
  ];

  const handleEditProducts = async (e) => {
    setIsEditMode(true);
    setEditData(e);
    setAddNewFormVisible(true);
    scrollTop();
  }

  const handleDeleteProducts = async (e) => {
    dispatch(deleteProductDataAction(e));
  }

  const btnList = [
    {
      id: 'delete',
      label: 'Xoá',
      icon: <DeleteIcon />,
      color: 'error',
      action: handleDeleteProducts
    },
    {
      id: 'edit',
      label: 'Sửa',
      icon: <BorderColorIcon />,
      color: 'success',
      action: handleEditProducts
    }
  ]
  
  return (
    <>
      <Container fluid className="custom-container">
        <div className='orders-content'>
          <h1>QUẢN LÝ SẢN PHẨM</h1>
          <Button variant="outlined" onClick={handleAddNewForm}>
            Thêm/Update sản phẩm mới
          </Button>
          <Collapse in={isAddNewFormVisible}>
            <div className='form-add'>
              <form onSubmit={formAddNewProduct.handleSubmit}>
                <Row className='form-field'>
                  <Col md={2}>
                    Mã SP:
                  </Col>
                  <Col md={5}>
                    <TextField
                      label="Mã sản phẩm"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      name="id"
                      value={formAddNewProduct.values.id}
                      onChange={(e) => formAddNewProduct.handleChange({
                        target: {
                          name: e.target.name,
                          value: e.target.value.toUpperCase(), // Convert to uppercase
                        },
                      })}
                      error={formAddNewProduct.touched.id && Boolean(formAddNewProduct.errors.id)}
                      helperText={formAddNewProduct.touched.id && formAddNewProduct.errors.id}
                    />
                  </Col>
                </Row>
                <Row className='form-field'>
                  <Col md={2}>
                    Tên SP:
                  </Col>
                  <Col md={5}>
                    <TextField
                      label="Tên sản phẩm"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      name="name"
                      value={formAddNewProduct.values.name}
                      onChange={formAddNewProduct.handleChange}
                      error={formAddNewProduct.touched.name && Boolean(formAddNewProduct.errors.name)}
                    />
                  </Col>
                </Row>
                <Row className='form-field'>
                  <Col md={2}>
                    Đơn vị:
                  </Col>
                  <Col md={5}>
                    <Select
                      fullWidth
                      labelId="product-select-label"
                      id="unit-select"
                      name="unit"
                      value={formAddNewProduct.values.unit}
                      onChange={formAddNewProduct.handleChange}
                      onBlur={formAddNewProduct.handleBlur}
                      error={formAddNewProduct.touched.unit && Boolean(formAddNewProduct.errors.unit)}
                    >
                      {unitsData.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Col>
                </Row>
                <Row className='form-field'>
                  <Col md={2}>
                    Giá bán:
                  </Col>
                  <Col md={5}>
                    <TextField
                      label="Giá bán"
                      type='number'
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      name="price"
                      value={formAddNewProduct.values.price}
                      onChange={formAddNewProduct.handleChange}
                      error={formAddNewProduct.touched.price && Boolean(formAddNewProduct.errors.price)}
                      helperText={formAddNewProduct.touched.price && formAddNewProduct.errors.price}
                    />
                  </Col>
                </Row>
                {!isEditMode && (
                  <Button
                    className='btn-login m10 custom-color'
                    variant="contained"
                    type="submit">
                    Tạo sản phẩm
                  </Button>
                )}

                {isEditMode && (
                  <>
                    <Button
                      className='btn-login m10 custom-color'
                      variant="contained"
                      type="submit">
                      Cập nhật sản phẩm
                    </Button>
                    <Button
                      className='btn-login m10 custom-color-cancel ml-10'
                      variant="contained"
                      type="submit"
                      onClick={() => {
                        formAddNewProduct.resetForm({ values: formAddNewProduct.initialValues });
                        setIsEditMode(false);
                      }}>
                      Huỷ
                    </Button>
                  </>
                )}

              </form>
            </div>
          </Collapse>

          <Button variant="outlined" className='mt-20'>
            Danh sách sản phẩm
          </Button>
          <DynamicTable
            columns={productsColumns}
            dataList={productData}
            buttonList={btnList}
          />
        </div>
      </Container>
    </>
  );
};

export default Products;
