import { getAllHistoryInventoryData, getAllInventoryData, setInventoryHistoryData } from "../../services/api_services";

// Action Types
export const SET_LOADING_TRUE = 'SET_LOADING_TRUE';
export const SET_LOADING_FALSE = 'SET_LOADING_FALSE';

// Action Creators
export const setLoadingRequest = () => ({
    type: SET_LOADING_TRUE,
    payload: true
});
export const setLoadingSuccess = () => ({
    type: SET_LOADING_FALSE,
    payload: false
});

// Thunk
export const startRequest = () => {
    return async (dispatch) => {
        dispatch(setLoadingRequest());
    };
};

export const endRequest = () => {
    return async (dispatch) => {
        dispatch(setLoadingSuccess());
    };
};
