import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import './Dashboard.scss';
import { formatNumber } from '../../services/utils';
import { getCashFlowData } from '../../services/api_services';
import { endRequest, startRequest } from '../../redux/actions/loadingActions';
import { useDispatch } from 'react-redux';

const Dashboard = () => {
    const pubURL = process.env.PUBLIC_URL;
    const dispatch = useDispatch();
    const [cashInData, setCashInData] = useState({});
    const [cashOutData, setCashOutData] = useState({});

    useEffect(() => {
      const fetchData = async () => {
        try {
          dispatch(startRequest());
          const data = await getCashFlowData();
          setCashInData(data.find(e => e.id === 'cash_in'));
          setCashOutData(data.find(e => e.id === 'cash_out'));
          dispatch(endRequest());
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
  
      fetchData();
    }, []);

    return (
        <>
            <Container fluid className="custom-container">
                <div className='dashboard-content'>
                    <h1>DASHBOARD</h1>
                    <div className='box'>
                        <h5>Tổng thu (VNĐ):</h5>
                        <h2>{formatNumber(cashInData.total_price)}</h2>
                    </div>
                    <div className='box'>
                        <h5>Tổng chi (VNĐ):</h5>
                        <h2>{formatNumber(cashOutData.total_price)}</h2>
                    </div>
                    <div className='box'>
                        <h5>Lợi nhuận hiện tại (VNĐ):</h5>
                        <h2>{formatNumber(cashInData.total_price - cashOutData.total_price)}</h2>
                    </div>
                </div>
            </Container>
        </>
    );
};

export default Dashboard;
