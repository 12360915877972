import { database } from '../firebase';
import { ref, get, query, orderByChild, equalTo, set, push, remove } from 'firebase/database';
import { once } from 'lodash';
import moment from 'moment';
import { generateID } from './utils';

export const env = process.env.REACT_APP_ENVIRONMENT;

export const getDataUserByUsername = async (username) => {
  try {
    const dataRef = ref(database, `${env}/Profiles`);
    const q = query(dataRef, orderByChild("username"), equalTo(username));
    const snapshot = await get(q);
    let userData = {};
    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        userData = childSnapshot.val();
      });
    }
    return userData;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const getAllProducts = async () => {
  try {
    const dataRef = ref(database, `${env}/Products`);
    const snapshot = await get(dataRef);
    const dataArray = Object.values(snapshot.val());
    return dataArray;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const getAllUnit = async () => {
  try {
    const dataRef = ref(database, `${env}/Unit`);
    const snapshot = await get(dataRef);
    return snapshot.val();
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const setOrderData = async (data) => {
  try {
    const dataRef = ref(database, `${env}/Orders`);
    await push(dataRef, data);
    await setExportInventoryData(data);
  } catch (error) {
    console.error('Error setting data:', error);
  }
};

export const deleteOrderData = async (data) => {
  try {
    const dataRef = ref(database, `${env}/Orders`);
    const q = query(dataRef, orderByChild("id"), equalTo(data.id));
    const snapshot = await get(q);

    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        remove(childSnapshot.ref);
      });
    }

    await setCashInData(-data.total_price);
    await setInventoryData(data);

  } catch (error) {
    console.error('Error deleting and completing order:', error);
  }
};

export const setCompleteOrderData = async (data) => {
  try {
    const dataRef = ref(database, `${env}/Orders`);
    const q = query(dataRef, orderByChild("id"), equalTo(data.id));
    const snapshot = await get(q);
    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        const orderData = childSnapshot.val();
        orderData.status = "DONE";
        set(childSnapshot.ref, orderData);
      });
    }
    await setCashInData(data.total_price);
  } catch (error) {
    console.error('Error setting data:', error);
  }
};

export const getAllOrders = async () => {
  try {
    const dataRef = ref(database, `${env}/Orders`);
    const snapshot = await get(dataRef);
    const dataArray = Object.values(snapshot.val()).map(order => ({
      ...order,
      date: moment(order.created_date, 'DD/MM/YYYY - HH:mm:ss').toDate(),
    }));

    dataArray.sort((a, b) => moment(b.date).diff(moment(a.date)));

    return dataArray;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const setInventoryHistoryData = async (data) => {
  try {
    const dataRef = ref(database, `${env}/WarehouseHistory`);
    data.id = generateID('SI', data.created_date);
    await push(dataRef, data);
    await setInventoryData(data);
    await setCashOutData(data.total_price);
  } catch (error) {
    console.error('Error setting data:', error.message);
    return { success: false, message: 'Failed to add data to Warehouse. Please try again.' };
  }
};

export const setInventoryData = async (data) => {
  try {
    const dataRef = ref(database, `${env}/WarehouseInventory`);
    const prepareDataInventory = {
      id: data.product_id,
      name: data.product_name,
      unit: data.unit,
      quantity: data.quantity || 0
    }

    const productQuery = query(
      dataRef,
      orderByChild('id'),
      equalTo(data.product_id)
    );

    const unitQuery = query(
      dataRef,
      orderByChild('unit'),
      equalTo(data.unit)
    );

    const productSnapshot = await get(productQuery);
    const unitSnapshot = await get(unitQuery);

    if (productSnapshot.exists() && unitSnapshot.exists()) {
      productSnapshot.forEach(async snapshot => {
        prepareDataInventory.quantity = snapshot.val().quantity + data.quantity;
        await set(ref(database, `${env}/WarehouseInventory/${snapshot.key}`), prepareDataInventory);
      });
    } else {
      await push(dataRef, prepareDataInventory);
    }

    return { success: true, message: 'Data successfully added/updated in WarehouseInventory.' };
  } catch (error) {
    console.error('Error setting data:', error.message);
    return { success: false, message: 'Failed to add/update data in WarehouseInventory. Please try again.' };
  }
}

export const setExportInventoryData = async (data) => {
  try {
    const dataRef = ref(database, `${env}/WarehouseInventory`);
    const prepareDataInventory = {
      id: data.product_id,
      name: data.product_name,
      unit: data.unit,
      quantity: data.quantity || 0
    }

    const productQuery = query(
      dataRef,
      orderByChild('id'),
      equalTo(data.product_id)
    );

    const unitQuery = query(
      dataRef,
      orderByChild('unit'),
      equalTo(data.unit)
    );

    const productSnapshot = await get(productQuery);
    const unitSnapshot = await get(unitQuery);

    if (productSnapshot.exists() && unitSnapshot.exists()) {
      productSnapshot.forEach(async snapshot => {
        prepareDataInventory.quantity = snapshot.val().quantity - data.quantity;
        await set(ref(database, `${env}/WarehouseInventory/${snapshot.key}`), prepareDataInventory);
      });
    } else {
      await push(dataRef, prepareDataInventory);
    }

    return { success: true, message: 'Data successfully added/updated in WarehouseInventory.' };
  } catch (error) {
    console.error('Error setting data:', error.message);
    return { success: false, message: 'Failed to add/update data in WarehouseInventory. Please try again.' };
  }
}

export const setCashOutData = async (total_price) => {
  try {
    const dataRef = ref(database, `${env}/CashFlow`);
    const q = query(dataRef, orderByChild("id"), equalTo("cash_out"));
    const snapshot = await get(q);

    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        const cashOutData = childSnapshot.val();
        cashOutData.total_price = (cashOutData.total_price || 0) + total_price;
        set(childSnapshot.ref, cashOutData);
      });
    } else {
      const newCashOutData = {
        id: "cash_out",
        name: "Chi",
        total_price: total_price
      };
      await push(dataRef, newCashOutData);
    }
  } catch (error) {
    console.error("Error fetching or updating data:", error);
  }
}

export const setCashInData = async (total_price, isSync = false) => {
  try {
    const dataRef = ref(database, `${env}/CashFlow`);
    const q = query(dataRef, orderByChild("id"), equalTo("cash_in"));
    const snapshot = await get(q);

    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        const cashInData = childSnapshot.val();
        if (isSync) {
          cashInData.total_price = total_price;
        } else {
          cashInData.total_price = (cashInData.total_price || 0) + total_price;
        }

        set(childSnapshot.ref, cashInData);
      });
    }
  } catch (error) {
    console.error("Error fetching or updating data:", error);
  }
}

export const getCashFlowData = async () => {
  try {
    const dataRef = ref(database, `${env}/CashFlow`);
    const q = query(dataRef);
    const snapshot = await get(q);

    if (snapshot.exists()) {
      const dataArray = Object.values(snapshot.val())
      return dataArray;
    }
  } catch (error) {
    console.error("Error fetching or updating data:", error);
  }
}

export const getAllHistoryInventoryData = async () => {
  try {
    const dataRef = ref(database, `${env}/WarehouseHistory`);
    const snapshot = await get(dataRef);
    const dataArray = Object.values(snapshot.val()).map(item => ({
      ...item,
      date: moment(item.created_date, 'DD/MM/YYYY - HH:mm:ss').toDate(),
    }));
    dataArray.sort((a, b) => moment(b.date).diff(moment(a.date)));

    return dataArray;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const getAllInventoryData = async () => {
  try {
    const dataRef = ref(database, `${env}/WarehouseInventory`);
    const snapshot = await get(dataRef);
    const dataArray = Object.values(snapshot.val());

    return dataArray;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const getInventoryDataByProductId = async (product_id) => {
  try {
    const dataRef = ref(database, `${env}/WarehouseInventory`);
    const q = query(dataRef, orderByChild("id"), equalTo(product_id));
    const snapshot = await get(q);
    if (snapshot.exists()) {
      let data = {};
      snapshot.forEach(childSnapshot => {
        data = childSnapshot.val();
      });
      return data;
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
}

export const setNewProductData = async (data) => {
  try {
    const dataRef = ref(database, `${env}/Products`);
    const productQuery = query(
      dataRef,
      orderByChild('id'),
      equalTo(data.id)
    );

    const productSnapshot = await get(productQuery);

    if (productSnapshot.exists()) {
      return { success: false, message: 'Mã SP đã tồn tại, xin vui lòng nhập mã SP khác' };
    }
    await push(dataRef, data);
    return { success: true, message: 'Data successfully added/updated.' };
  } catch (error) {
    console.error('Error setting data:', error.message);
    throw { success: false, message: 'Failed to add/update data. Please try again.' };
  }
};

export const updateProductData = async (data) => {
  try {
    const dataRef = ref(database, `${env}/Products`);
    const q = query(dataRef, orderByChild("id"), equalTo(data.id));
    const snapshot = await get(q);

    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        set(childSnapshot.ref, data);
        
      });
      return { success: true, message: 'Data successfully added/updated.' };
    }
  } catch (error) {
    console.error("Error fetching or updating data:", error);
  }
}

export const deleteProductData = async (data) => {
  try {
    const dataRef = ref(database, `${env}/Products`);
    const q = query(dataRef, orderByChild("id"), equalTo(data.id));
    const snapshot = await get(q);

    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        remove(childSnapshot.ref);
      });
    }
  } catch (error) {
    console.error('Error deleting and completing order:', error);
  }
};