export const MenuList = [
    {
        name: 'Dashboard',
        path: '/home/dashboard'
    },
    {
        name: 'Quản lí hồ sơ',
        path: '/home/profile'
    },
    {
        name: 'Quản lí đơn hàng',
        path: '/home/orders'
    },
    {
        name: 'Quản lí kho',
        path: '/home/inventory'
    },
    {
        name: 'Quản lí sản phẩm',
        path: '/home/products'
    }
]