// DynamicTable.jsx
import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  Tooltip,
  TablePagination,
} from '@mui/material';
import { formatNumber } from '../../services/utils';
import './Table.scss';
import { CONSTANT } from '../../constant/constant';

const rowsPerPageOptions = [10, 25, 50];

const DynamicTable = ({ dataList, columns, buttonList, isHaveStatus = false }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);

  const genClassStatus = (row, column) => {
    if (column.id === CONSTANT.STATUS) {
      return row[column.id] === CONSTANT.DONE ? CONSTANT.STATUS_SUCCESS : CONSTANT.STATUS_INPROGRESS;
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const genColumnAction = (row) => {
    if (isHaveStatus) {
      if (row.status !== CONSTANT.DONE) {
        return (
          <TableCell>
            {(buttonList || buttonList.length > 0) && buttonList.map((button, index) => (
              <Tooltip key={index} title={button.label} arrow>
                <IconButton aria-label={button.label} color={button.color} onClick={() => button.action(row)}>
                  {button.icon}
                </IconButton>
              </Tooltip>
            ))}
          </TableCell>
        )
      } else {
        return (
          <TableCell></TableCell>
        )
      }
    } else {
      return (
        <TableCell>
          {buttonList && buttonList.map((button, index) => (
            <Tooltip key={index} title={button.label} arrow>
              <IconButton aria-label={button.label} color={button.color} onClick={() => button.action(row)}>
                {button.icon}
              </IconButton>
            </Tooltip>
          ))}
        </TableCell>
      )
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Paper>
      <TableContainer className='tbl-content' tyle={{ maxHeight: '400px', overflowY: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell className='tbl-header' key={column.id}>
                  {column.label}
                </TableCell>
              ))}
              <TableCell className='tbl-header'></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataList
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {columns.map((column) => (
                    <TableCell key={column.id} className={genClassStatus(row, column)}>
                      {column.isFormatNumber
                        ? formatNumber(row[column.id])
                        : row[column.id]}
                    </TableCell>
                  ))}
                  {genColumnAction(row)}
                </TableRow>
              ))}
            {dataList.length === 0 && (
              <TableRow>
                <TableCell colSpan={12} className='text-center'>
                  Không có dữ liệu để hiển thị
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={dataList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={isMobileView ? "" : "Số hàng mỗi trang"}
        className='tbl-pagination'
      />
    </Paper>
  );
};

export default DynamicTable;
