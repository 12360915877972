import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import './Error.scss';
import ErrorIcon from '../../assets/images/error-icon-2.png';

const Error = () => {
    const pubURL = process.env.PUBLIC_URL;
    return (
        <Container fluid className="custom-container">
            <div className='error-content'>
                <img src={ErrorIcon} />
                <div>
                    <h3>Sorry! We encountered an issue...</h3>
                    <h3>but we'll be back soon!</h3>
                </div>
            </div>
        </Container>
    );
};

export default Error;
