import { deleteOrderData, getAllOrders, getAllProducts, setCompleteOrderData, setOrderData } from "../../services/api_services";
import { getAllInventoryDataAction } from "./inventoryActions";
import { endRequest, startRequest } from "./loadingActions";

// Action Types
export const FETCH_ORDER_DATA_REQUEST = 'FETCH_ORDER_DATA_REQUEST';
export const FETCH_ORDER_DATA_SUCCESS = 'FETCH_ORDER_DATA_SUCCESS';
export const FETCH_ORDER_DATA_FAILURE = 'FETCH_ORDER_DATA_FAILURE';

export const SET_ORDER_DATA_REQUEST = 'SET_ORDER_DATA_REQUEST';
export const SET_ORDER_DATA_SUCCESS = 'SET_ORDER_DATA_SUCCESS';
export const SET_ORDER_DATA_FAILURE = 'SET_ORDER_DATA_FAILURE';


// Action Creators
export const setDataRequest = () => ({
    type: SET_ORDER_DATA_REQUEST,
});

export const setDataSuccess = () => ({
    type: SET_ORDER_DATA_SUCCESS,
});

export const setDataFailure = (error) => ({
    type: SET_ORDER_DATA_FAILURE,
    payload: error,
});

// Action Creators
export const fetchOrderDataRequest = () => ({
    type: FETCH_ORDER_DATA_REQUEST,
});

export const fetchOrderDataSuccess = (data) => ({
    type: FETCH_ORDER_DATA_SUCCESS,
    payload: data,
});

export const fetchOrderDataFailure = (error) => ({
    type: FETCH_ORDER_DATA_FAILURE,
    payload: error,
});

// Thunk
export const setOrderDataAction = (data) => {
    return async (dispatch) => {
        dispatch(startRequest());
        try {
            await setOrderData(data);
            dispatch(setDataSuccess());
            dispatch(getAllOrdersDataAction());
            dispatch(getAllInventoryDataAction());
            dispatch(endRequest());
        } catch (error) {
            dispatch(setDataFailure(error));
            dispatch(endRequest());
        }
    };
};

export const setCompleteOrderDataAction = (data) => {
    return async (dispatch) => {
        dispatch(startRequest());
        try {
            await setCompleteOrderData(data);
            dispatch(setDataSuccess());
            dispatch(getAllOrdersDataAction());
            dispatch(endRequest());
        } catch (error) {
            dispatch(setDataFailure(error));
            dispatch(endRequest());
        }
    };
};

export const getAllOrdersDataAction = () => {
    return async (dispatch) => {
        dispatch(startRequest());
        try {
            const productsData = await getAllOrders();
            dispatch(fetchOrderDataSuccess(productsData));
            dispatch(endRequest());
        } catch (error) {
            dispatch(fetchOrderDataFailure(error));
            dispatch(endRequest());
        }
    };
};

export const deleteOrderDataAction = (data) => {
    return async (dispatch) => {
        dispatch(startRequest());
        try {
            await deleteOrderData(data);
            dispatch(setDataSuccess());
            dispatch(getAllOrdersDataAction());
            dispatch(getAllInventoryDataAction());
            dispatch(endRequest());
        } catch (error) {
            dispatch(setDataFailure(error));
            dispatch(endRequest());
        }
    };
};



