const initialState = {
    ordersData: null,
};

const orderReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_ORDER_DATA_REQUEST':
            return { ...state, loading: true, error: null };
        case 'FETCH_ORDER_DATA_SUCCESS':
            return { ...state, loading: false, ordersData: action.payload };
        case 'FETCH_ORDER_DATA_FAILURE':
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

export default orderReducer;