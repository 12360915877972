import { deleteProductData, getAllProducts, getAllUnit, setNewProductData, updateProductData } from "../../services/api_services";
import { endRequest, startRequest } from "./loadingActions";

// Action Types
export const FETCH_PRODUCT_DATA_REQUEST = 'FETCH_PRODUCT_DATA_REQUEST';
export const FETCH_UNIT_DATA_SUCCESS = 'FETCH_UNIT_DATA_SUCCESS';
export const FETCH_PRODUCT_DATA_SUCCESS = 'FETCH_PRODUCT_DATA_SUCCESS';
export const FETCH_PRODUCT_DATA_FAILURE = 'FETCH_PRODUCT_DATA_FAILURE';

export const SET_PRODUCT_DATA_REQUEST = 'SET_PRODUCT_DATA_REQUEST';
export const SET_PRODUCT_DATA_SUCCESS = 'SET_PRODUCT_DATA_SUCCESS';
export const SET_PRODUCT_DATA_FAILURE = 'SET_PRODUCT_DATA_FAILURE';

// Action Creators
export const fetchDataRequest = () => ({
  type: FETCH_PRODUCT_DATA_REQUEST,
});

export const fetchDataSuccess = (data) => ({
  type: FETCH_PRODUCT_DATA_SUCCESS,
  payload: data,
});

export const fetchUnitDataSuccess = (data) => ({
  type: FETCH_UNIT_DATA_SUCCESS,
  payload: data,
});


export const fetchDataFailure = (error) => ({
  type: FETCH_PRODUCT_DATA_FAILURE,
  payload: error,
});

export const setProductRequest = () => ({
  type: SET_PRODUCT_DATA_REQUEST,
});

export const setProductSuccess = () => ({
  type: SET_PRODUCT_DATA_SUCCESS,
});

export const setProductFailure = (error) => ({
  type: SET_PRODUCT_DATA_FAILURE,
  payload: error,
});

// Thunk
export const getAllProductsData = () => {
  return async (dispatch) => {
    dispatch(startRequest());
    try {
      const productsData = await getAllProducts();
      dispatch(fetchDataSuccess(productsData));
      dispatch(endRequest());
    } catch (error) {
      dispatch(fetchDataFailure(error));
      dispatch(endRequest());
    }
  };
};

export const getAllUnitData = () => {
  return async (dispatch) => {
    dispatch(startRequest());
    try {
      const productsData = await getAllUnit();
      dispatch(fetchUnitDataSuccess(productsData));
      dispatch(endRequest());
    } catch (error) {
      dispatch(fetchDataFailure(error));
      dispatch(endRequest());
    }
  };
};

export const setNewProductDataAction = (data) => {
  return async (dispatch) => {
    dispatch(startRequest());
    try {
      const productsData = await setNewProductData(data);
      dispatch(endRequest());
      return productsData;
    } catch (error) {
      dispatch(setProductFailure(error));
      throw error;
    }
  };
};

export const updateProductDataAction = (data) => {
  return async (dispatch) => {
    dispatch(startRequest());
    try {
      const productsData = await updateProductData(data);
      dispatch(getAllProductsData());
      dispatch(endRequest());
      return productsData;
    } catch (error) {
      dispatch(setProductFailure(error));
      dispatch(endRequest());
      throw error;
    }
  };
};

export const deleteProductDataAction = (data) => {
  return async (dispatch) => {
    dispatch(startRequest());
    try {
      const productsData = await deleteProductData(data);
      dispatch(getAllProductsData());
      dispatch(endRequest());
      return productsData;
    } catch (error) {
      dispatch(setProductFailure(error));
      dispatch(endRequest());
      throw error;
    }
  };
};



