import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, Redirect } from 'react-router-dom';

import Login from './pages/login/Login';
import Home from './pages/home/Home';
import Error from './pages/error/Error';
import { getWithOneDayExpiration } from './services/storage_utils';

const PrivateRoute = ({ element }) => {
  // const isAuthenticated = sessionStorage.getItem('authenticated');
  const retrievedValue = getWithOneDayExpiration('authenticated');
  return retrievedValue ? element : <Navigate to="/" replace />;
};

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={<Login />}
        />
        <Route
          path="/home/*"
          element={<PrivateRoute element={<Home />} />}
        />
        {/* Redirect to the error page for any unknown route */}
        <Route path="/error-page" element={<Error />} />
        <Route path="*" element={<Navigate to="/error-page" />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
