// Sidebar.jsx
import React, { useState } from 'react';
import { Nav, Navbar, Container } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { FiMenu } from 'react-icons/fi'; // Hamburger icon from react-icons
import './Sidebar.scss';
import { getWithOneDayExpiration } from '../../services/storage_utils';
import { MenuList } from '../../constant/menu_list';

const Sidebar = () => {
  const pubURL = process.env.PUBLIC_URL;
  const location = useLocation();
  const [expanded, setExpanded] = useState(false);

  const retrievedValue = getWithOneDayExpiration('authenticated');

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <Container className="p-0">
      <Navbar expand="md" variant="light" bg="light" expanded={expanded}>
        <Navbar.Toggle onClick={handleToggle} aria-controls="basic-navbar-nav">
          <FiMenu />
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav" className="full-height-sidebar">
          <Nav className="flex-column">
            <Navbar.Brand className="d-none d-md-block text-center">
              <img src={pubURL + '/main_logo.png'} height="100" className="d-inline-block align-top" />
            </Navbar.Brand>
            {MenuList.map((item, index) => (
              <Nav.Link
                key={index}
                as={Link}
                to={item.path}
                onClick={() => setExpanded(false)}
                className={location.pathname === item.path ? 'active' : ''}
              >
                {item.name}
              </Nav.Link>
            ))}
            {retrievedValue.role === 'admin' && (
              <Nav.Link
                as={Link}
                to="/home/admin-console"
                onClick={() => setExpanded(false)}
                className={location.pathname === '/home/admin-console' ? 'active' : ''}
              >
                Admin Console
              </Nav.Link>
            )}
            <Nav.Link
              as={Link}
              onClick={() => {
                localStorage.removeItem('authenticated');
              }}
              to="/"
            >
              Đăng Xuất
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Container>
  );
};

export default Sidebar;
