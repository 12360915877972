import { database } from '../firebase';
import { ref, get, query, orderByChild, equalTo, set, push, child } from 'firebase/database';
import { once } from 'lodash';
import moment from 'moment';
import { generateID } from './utils';
import { setCashInData } from './api_services';

export const env = process.env.REACT_APP_ENVIRONMENT;

export const syncOrdersData = async () => {
    try {
        const dataRef = ref(database, `${env}/Orders`);
        const snapshot = await get(dataRef);
        let countPrice = 0;

        if (snapshot.exists()) {
            snapshot.forEach(orderSnapshot => {
                const orderId = orderSnapshot.key;
                const orderData = orderSnapshot.val();
                const genID = generateID('SI', orderData.created_date);
                if (orderData.status == "DONE") {
                    countPrice += orderData.total_price;
                }
                if (!orderData.id) {
                    orderData.id = genID;
                }
                if (!orderData.unit) {
                    orderData.unit = "Hủ";
                }
                if (!orderData.status) {
                    orderData.status = "IN_PROGRESS";
                }
                set(ref(database, `${env}/Orders/${orderId}`), orderData);

            });
            await setCashInData(countPrice, true);
        }
    } catch (error) {
        console.error("Error fetching or updating data:", error);
    }
};

export const syncDataFromProdToDev = async () => {
    try {
        const dataRef = ref(database, 'production');
        const snapshot = await get(dataRef);

        if (snapshot.exists()) {
            const data = snapshot.val();
            set(ref(database, 'development'), data);
        }
    } catch (error) {
        console.error("Error fetching or updating data:", error);
    }
};

export const syncDataFromDevToProd = async () => {
    try {
        const dataRef = ref(database, 'development');
        const snapshot = await get(dataRef);

        if (snapshot.exists()) {
            const data = snapshot.val();
            set(ref(database, 'production'), data);
        }
    } catch (error) {
        console.error("Error fetching or updating data:", error);
    }
};


export const syncInventoryData = async () => {
    try {
        const dataRef = ref(database, `${env}/Orders`);
        const snapshot = await get(dataRef);
        let countQuantity500 = 0;
        let countQuantity250 = 0;
        if (snapshot.exists()) {
            snapshot.forEach(orderSnapshot => {
                const orderId = orderSnapshot.key;
                const orderData = orderSnapshot.val();
                if (orderData.product_id == "KB0500") {
                    countQuantity500 += orderData.quantity;
                } else {
                    countQuantity250 += orderData.quantity;
                }
            });
        }
        const dataInventoryRef = ref(database, `${env}/WarehouseInventory`);
        const snapshotInventory = await get(dataInventoryRef);
        if (snapshotInventory.exists()) {
            snapshotInventory.forEach(invSnapshot => {
                const invId = invSnapshot.key;
                const invData = invSnapshot.val();
                if(invData.id == "KB0500"){
                    invData.quantity = invData.quantity - countQuantity500;
                }else{
                    invData.quantity = invData.quantity - countQuantity250;
                }
                set(ref(database, `${env}/WarehouseInventory/${invId}`), invData);
            });
        }
    } catch (error) {
        console.error("Error fetching or updating data:", error);
    }
};