// Home.js
import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import HomeRoutes from './HomeRoutes';  // Import HomeRoutes
import Sidebar from '../../components/sidebar/Sidebar';
import { useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import './Home.scss';

const Home = () => {
  const { loading } = useSelector(state => state);

  return (
    <Container fluid className="">
      <div className='home-content'>
        <Row>
          <Col md={4} className='p-0'>
            <Sidebar />
          </Col>
          <Col md={8} className={`p-0 ${loading ? 'loading-indicator visible' : 'disable'}`}>
            <Box sx={{ display: 'flex' }}>
              <CircularProgress />
            </Box>
          </Col>
          <Col md={8} className={`p-0 ${loading ? 'disable' : 'visible'}`}>
            <Outlet />
            <HomeRoutes />
          </Col>
        </Row>
      </div>
    </Container >
  );
};



export default Home;
