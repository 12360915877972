export const storeWithOneDayExpiration = (key, value) => {
    const now = new Date();
    const expiration = new Date(now.getTime() + 24 * 60 * 60 * 1000); // 1 day in milliseconds
    const item = {
        username: value.username,
        role: value.role,
        expiration: expiration.getTime(),
    };
    localStorage.setItem(key, JSON.stringify(item));
};

export const getWithOneDayExpiration = (key) => {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
        return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    if (now.getTime() > item.expiration) {
        localStorage.removeItem(key);
        return null;
    }
    return item;
};