import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Profile.scss';
import { TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getUserInfoByUsername } from '../../redux/actions/userAction';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const Profile = () => {
  const username = JSON.parse(localStorage.getItem('authenticated')).username;
  const dispatch = useDispatch();
  const { userData } = useSelector(state => state.userData);

  useEffect(() => {
    if (!userData) {
      dispatch(getUserInfoByUsername(username));
    }
    if (userData) {
      formik.setValues({
        fullname: userData.fullname || '',
        dob: userData.dob,
        role: userData.role
      });
    }
  }, [userData]);

  const formik = useFormik({
    initialValues: {
      fullname: '',
      dob: '',
      role: ''
    },
    validationSchema: Yup.object({
      fullname: Yup.string().required('Họ và tên không được để trống!'),
    }),
    onSubmit: (userData) => {
    },
  });

  return (
    <>
      <Container fluid className="custom-container">
        <div className='profile-content'>
          <h1>THÔNG TIN HỒ SƠ</h1>
          <Row className='form-field'>
            <Col md={3}>Họ tên:</Col>
            <Col md={7}>
              <TextField
                name='fullname'
                className='fullname'
                variant="outlined"
                value={formik.values.fullname}
                disabled
              />
            </Col>
            <Col md={2}>
              {/* Edit Button */}
            </Col>
          </Row>
          <Row className='form-field'>
            <Col md={3}>Ngày sinh:</Col>
            <Col md={7}>
              <TextField
                name='dob'
                className='fullname'
                variant="outlined"
                value={formik.values.dob}
                disabled
              />
            </Col>
            <Col md={2}>
              {/* Edit Button */}
            </Col>
          </Row>
          <Row className='form-field'>
            <Col md={3}>Role:</Col>
            <Col md={7}>
              <h5 className='text-disabled'>{formik.values.role}</h5>
            </Col>
            <Col md={2}>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default Profile;
