// HomeRoutes.js
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from '../dashboard/Dashboard';
import Profile from '../profile/Profile';
import { getWithOneDayExpiration } from '../../services/storage_utils';
import Orders from '../orders/Orders';
import Inventory from '../inventory/Inventory';
import AdminConsole from '../admin/Admin';
import Products from '../products/Products';

const PrivateRoute = ({ element }) => {
    // const isAuthenticated = sessionStorage.getItem('authenticated');
    const retrievedValue = getWithOneDayExpiration('authenticated');
    return retrievedValue ? element : <Navigate to="/" replace />;
};

const HomeRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Navigate to="dashboard" replace />} />
            <Route
                path="dashboard"
                element={<PrivateRoute element={<Dashboard />} />}
            />
            <Route
                path="profile"
                element={<PrivateRoute element={<Profile />} />}
            />
            <Route
                path="orders"
                element={<PrivateRoute element={<Orders />} />}
            />
            <Route
                path="inventory"
                element={<PrivateRoute element={<Inventory />} />}
            />
            <Route
                path="products"
                element={<PrivateRoute element={<Products />} />}
            />
            <Route
                path="admin-console"
                element={<PrivateRoute element={<AdminConsole />} />}
            />
            <Route path="*" element={<Navigate to="/error-page" />} />
        </Routes>
    );
};

export default HomeRoutes;
