import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Orders.scss';
import { TextField, Collapse, Button, Select, MenuItem, } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getAllProductsData, getAllUnitData } from '../../redux/actions/productActions';
import { getAllOrdersDataAction, setCompleteOrderDataAction, setOrderDataAction, deleteOrderDataAction } from '../../redux/actions/orderActions';
import moment from 'moment';
import DynamicTable from '../../components/table/Table';
import { generateID } from '../../services/utils';
import { getInventoryDataByProductId } from '../../services/api_services';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import SearchIcon from '@mui/icons-material/Search';
import BackspaceIcon from '@mui/icons-material/Backspace';
import DeleteIcon from '@mui/icons-material/Delete';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { CONSTANT } from '../../constant/constant';

const Orders = () => {
  const dispatch = useDispatch();
  const { productsData, unitData } = useSelector(state => state.productsData);
  const { ordersData } = useSelector(state => state.ordersData);
  const [productData, setProductData] = useState([])
  const [orders, setOrders] = useState([])
  const [isFormVisible, setFormVisible] = useState(false);
  const [isTableVisible, setTableVisible] = useState(false);
  const [unitsData, setUnitsData] = useState([]);
  const [inventoryDataById, setInventoryDataById] = useState(null);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (!productsData) {
      dispatch(getAllProductsData());
    } else {
      setProductData(productsData);
    }
  }, [productsData]);

  useEffect(() => {
    if (!ordersData) {
      dispatch(getAllOrdersDataAction());
    } else {
      setOrders(ordersData);
    }
  }, [ordersData]);

  useEffect(() => {
    if (!unitData) {
      dispatch(getAllUnitData());
    } else {
      setUnitsData(unitData);
    }
  }, [unitData]);

  useEffect(() => {
    if (ordersData) {
      const val = searchValue.toLocaleLowerCase();
      const filteredData = ordersData.filter(order => order.customer_name.toLowerCase().includes(val) || order.status.toLowerCase().includes(val));
      if (searchValue) {
        setOrders(filteredData);
      } else {
        setOrders(ordersData);
      }
    }
  }, [searchValue, ordersData]);

  const formAddNewOrders = useFormik({
    initialValues: {
      id: '',
      customer_name: '',
      product_id: '',
      product_name: '',
      quantity: 0,
      shipping_fee: 0,
      total_price: 0,
      created_date: '',
      discount: 0,
      status: CONSTANT.IN_PROGRESS,
      unit: ''
    },
    validationSchema: Yup.object({
      customer_name: Yup.string().required('Họ tên KH không được để trống'),
      quantity: Yup.number()
        .test('max-inventory', 'Số lượng sản phẩm đang vượt kho', function (value) {
          const inventoryQuantity = inventoryDataById?.quantity || 0;
          return value <= inventoryQuantity;
        }),
      product_id: Yup.string().required('Vui lòng chọn sản phẩm'),
      unit: Yup.string().required('Vui lòng chọn đơn vị'),
    }),
    onSubmit: async (order) => {
      order.created_date = moment(new Date()).format('DD/MM/YYYY - HH:mm:ss');
      order.id = generateID('OD', order.created_date);
      dispatch(await setOrderDataAction(order));
      handleResetForm(formAddNewOrders);
      setFormVisible(false);
      setTableVisible(true);
    },
  });

  useEffect(() => {
    formAddNewOrders.setFieldValue("total_price", calcTotalPrice());
  }, [
    formAddNewOrders.values.product_id,
    formAddNewOrders.values.quantity,
    formAddNewOrders.values.shipping_fee,
    formAddNewOrders.values.discount
  ]);

  const handleResetForm = (form) => {
    form.resetForm({ values: formAddNewOrders.initialValues });
  }

  const handleToggleForm = () => {
    setFormVisible(!isFormVisible);
    handleResetForm(formAddNewOrders);
    setTableVisible(false);
  };

  const handleToggleTable = () => {
    setTableVisible(!isTableVisible);
    setFormVisible(false);
  };

  const findPriceByProductId = (product_id) => {
    const prod = productData.find(item => item.id === product_id);
    return prod ? prod.price : 0;
  }

  const calcTotalPrice = () => {
    return formAddNewOrders.values.quantity * findPriceByProductId(formAddNewOrders.values.product_id) + formAddNewOrders.values.shipping_fee - formAddNewOrders.values.discount;
  }

  const handleCompleteOrder = async (e) => {
    dispatch(await setCompleteOrderDataAction(e));
  }

  const handleDeleteOrder = async (e) => {
    dispatch(await deleteOrderDataAction(e));
  }

  const getInventoryDataById = async (id) => {
    const data = await getInventoryDataByProductId(id);
    setInventoryDataById(data);
  }

  const orderColumns = [
    { id: 'customer_name', label: 'Tên KH' },
    { id: 'product_name', label: 'Tên SP' },
    { id: 'quantity', label: 'Số lượng' },
    { id: 'discount', label: 'Chiết khấu (VNĐ)', isFormatNumber: true },
    { id: 'total_price', label: 'Tổng tiền (VNĐ)', isFormatNumber: true },
    { id: 'created_date', label: 'Ngày tạo' },
    { id: 'status', label: 'Trạng thái' },
  ];

  const btnList = [
    {
      id: 'complete',
      label: 'Hoàn thành',
      action: handleCompleteOrder,
      icon: <AssignmentTurnedInIcon />,
      color: 'primary',
    },
    {
      id: 'delete',
      label: 'Xoá',
      action: handleDeleteOrder,
      icon: <DeleteIcon />,
      color: 'error'
    }
  ]

  return (
    <Container fluid className="custom-container">
      <div className='orders-content'>
        <h1>QUẢN LÝ ĐƠN HÀNG</h1>
        <Button variant="outlined" onClick={handleToggleForm}>
          Tạo mới đơn hàng
        </Button>
        <Collapse in={isFormVisible}>
          <div className='form-add'>
            <form onSubmit={formAddNewOrders.handleSubmit}>
              <Row className='form-field'>
                <Col md={2}>
                  Họ tên Khách Hàng:
                </Col>
                <Col md={5}>
                  <TextField
                    label="Họ tên Khách Hàng"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="customer_name"
                    value={formAddNewOrders.values.customer_name}
                    onChange={formAddNewOrders.handleChange}
                    error={formAddNewOrders.touched.customer_name && Boolean(formAddNewOrders.errors.customer_name)}
                  />
                </Col>
              </Row>
              <Row className='form-field'>
                <Col md={2}>
                  Chọn sản phẩm:
                </Col>
                <Col md={5}>
                  <Select
                    fullWidth
                    labelId="product-select-label"
                    id="product-select"
                    name="product_id"
                    value={formAddNewOrders.values.product_id}
                    onChange={(e) => {
                      formAddNewOrders.handleChange(e);
                      formAddNewOrders.setFieldValue("product_name", productData.find(item => item.id == e.target.value).name);
                      getInventoryDataById(e.target.value);
                    }}
                    onBlur={formAddNewOrders.handleBlur}
                    error={formAddNewOrders.touched.product_id && Boolean(formAddNewOrders.errors.product_id)}
                  >
                    {productData.map((product) => (
                      <MenuItem key={product.id} value={product.id}>
                        {product.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Col>
              </Row>
              <Row className='form-field'>
                <Col md={2}>
                  Số lượng:
                </Col>
                <Col md={5}>
                  <TextField
                    label="Số lượng"
                    type='number'
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="quantity"
                    value={formAddNewOrders.values.quantity}
                    onChange={formAddNewOrders.handleChange}
                    inputProps={{ min: 0, step: 0.1 }}
                    error={formAddNewOrders.touched.quantity && Boolean(formAddNewOrders.errors.quantity)}
                    helperText={formAddNewOrders.touched.quantity && formAddNewOrders.errors.quantity}
                  />
                </Col>
              </Row>
              <Row className='form-field'>
                <Col md={2}>
                  Đơn vị:
                </Col>
                <Col md={5}>
                  <Select
                    fullWidth
                    labelId="product-select-label"
                    id="unit-select"
                    name="unit"
                    value={formAddNewOrders.values.unit}
                    onChange={formAddNewOrders.handleChange}
                    onBlur={formAddNewOrders.handleBlur}
                    error={formAddNewOrders.touched.unit && Boolean(formAddNewOrders.errors.unit)}
                  >
                    {unitsData.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Col>
              </Row>
              <Row className='form-field'>
                <Col md={2}>
                  Phí vận chuyển:
                </Col>
                <Col md={5}>
                  <TextField
                    label="Phí vận chuyển"
                    type='number'
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="shipping_fee"
                    value={formAddNewOrders.values.shipping_fee}
                    onChange={formAddNewOrders.handleChange}
                    inputProps={{ min: 0 }}
                  />
                </Col>
              </Row>
              <Row className='form-field'>
                <Col md={2}>
                  Chiết khấu:
                </Col>
                <Col md={5}>
                  <TextField
                    label="Chiết khấu"
                    type='number'
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="discount"
                    value={formAddNewOrders.values.discount}
                    onChange={formAddNewOrders.handleChange}
                    inputProps={{ min: 0 }}
                  />
                </Col>
              </Row>

              <Row className='form-field'>
                <Col md={2}>
                  <h5 className='total'>Tổng cộng:</h5>
                </Col>
                <Col md={5}>
                  <TextField
                    type='number'
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="total_price"
                    value={formAddNewOrders.values.total_price}
                    disabled
                  />
                </Col>
              </Row>
              <Button
                className='btn-login m10 custom-color'
                variant="contained"
                type="submit">
                Tạo đơn hàng
              </Button>
            </form>
          </div>
        </Collapse>
        <Button variant="outlined" className='mt-20' onClick={handleToggleTable}>
          Danh sách đơn hàng
        </Button>

        <Collapse in={isTableVisible}>
          <Row className='form-field mt-20'>
            <Col md={5}>
              <FormControl fullWidth sx={{ m: 1 }}>
                <InputLabel htmlFor="outlined-adornment-amount">Search</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-amount"
                  startAdornment={<SearchIcon position="start"></SearchIcon>}
                  endAdornment={<BackspaceIcon className='btn-clear' position="end" onClick={() => {
                    setSearchValue("");
                  }}>
                  </BackspaceIcon>
                  }
                  label="Search"
                  value={searchValue}
                  onChange={(e) => { setSearchValue(e.target.value) }}
                  placeholder='Tìm theo Tên KH hoặc Trạng thái'
                />
              </FormControl>
            </Col>
          </Row>
          <div className='form-add'>
            <DynamicTable
              columns={orderColumns}
              dataList={orders}
              buttonList={btnList}
              isHaveStatus={true}
            />
          </div>
        </Collapse>
      </div>
    </Container>
  );
};

export default Orders;
