// Import the necessary modules from the Firebase package (version 9 or later)
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase, ref } from 'firebase/database';

// Firebase configuration
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DB,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID
};
// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Get the Auth and Database instances
const auth = getAuth(firebaseApp);
const database = getDatabase(firebaseApp);

// Now, you can use the ref() function to get a reference to the database
const databaseRef = ref(database);

export { auth, database, databaseRef };
