import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import './Login.scss';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { database } from '../../firebase';
import { ref, get } from 'firebase/database';
import { encryptPassword } from '../../services/utils';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { storeWithOneDayExpiration, getWithOneDayExpiration } from '../../services/storage_utils';

const Login = () => {
    const pubURL = process.env.PUBLIC_URL;
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');

    const handleLogin = async (values) => {
        try {
            // Reference to the 'Users' node in your Firebase database
            const userRef = ref(database, `${process.env.REACT_APP_ENVIRONMENT}/Users`);
            const snapshot = await get(userRef);

            if (snapshot.exists()) {
                const usersData = snapshot.val();
                const matchingUser = usersData.find(
                    (user) => user.username === values.username
                );
                if (matchingUser) {
                    if (matchingUser.password === encryptPassword(values.password)) {
                        setErrorMessage('');
                        storeWithOneDayExpiration('authenticated', matchingUser);
                        navigate('/home');
                        return;
                    }
                }
            }
            setErrorMessage('Sai tên đăng nhập hoặc mật khẩu');
            return;
        } catch (error) {
            setErrorMessage('Đăng nhập thất bại');
        }
    };

    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
        },
        validationSchema: Yup.object({
            username: Yup.string().required('Username is required'),
            password: Yup.string().required('Password is required'),
        }),
        onSubmit: (userData) => {
            if (_.isEmpty(formik.errors)) {
                handleLogin(userData);
            }
        },
    });

    useEffect(() => {
        const retrievedValue = getWithOneDayExpiration('authenticated');
        if (retrievedValue !== null) {
            navigate('/home');
            return
        }
    }, []);

    return (
        <>
            <Container fluid className="custom-container">
                <div className='login-content'>
                    <div className='presentation'>
                        <img src={pubURL + '/main_logo.png'} className='logo' />
                        <h1 className='title'>Chào mừng bạn đã đến với PÉ NĂM!</h1>
                        <h5>Vui lòng đăng nhập để tiếp tục...</h5>
                    </div>
                    <form onSubmit={formik.handleSubmit}>
                        <div className='login-form'>
                            <TextField
                                className="custom-color m-10"
                                label="Username"
                                name='username'
                                variant="outlined"
                                value={formik.values.username}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.username && Boolean(formik.errors.username)}
                                helperText={formik.touched.username && formik.errors.username}
                            />
                            <TextField
                                className="custom-color m-10"
                                label="Password"
                                name='password'
                                variant="outlined"
                                type="password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                helperText={formik.touched.password && formik.errors.password}
                            />
                            <h5 className='error-message text-center'>{errorMessage}</h5>
                            <Button
                                className='btn-login m10 custom-color'
                                variant="contained"
                                type="submit"
                            >
                                Đăng Nhập
                            </Button>
                        </div>
                    </form>
                </div>
            </Container>

        </>
    );
};

export default Login;
