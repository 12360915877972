import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Inventory.scss';
import { TextField, Collapse, Button, Select, MenuItem, } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { getAllProductsData, getAllUnitData } from '../../redux/actions/productActions';
import { getAllHistoryInventoryDataAction, getAllInventoryDataAction, setInventoryDataAction } from '../../redux/actions/inventoryActions';
import DynamicTable from '../../components/table/Table';

const Inventory = () => {
    const pubURL = process.env.PUBLIC_URL;
    const dispatch = useDispatch();
    const { productsData, unitData } = useSelector(state => state.productsData);
    const { inventoryHistoryData, inventoryData } = useSelector(state => state.inventoryData);
    const [productData, setProductData] = useState([]);
    const [unitsData, setUnitsData] = useState([]);
    const [inventoryHistory, setInventoryHistory] = useState([]);
    const [inventoriesData, setInventoriesData] = useState([]);

    const [isAddFormVisible, setAddFormVisible] = useState(false);
    const [isHistoryVisible, setHistoryVisible] = useState(false);
    const [isInventoryVisible, setInventoryVisible] = useState(false);

    const historyColumns = [
        { id: 'product_id', label: 'Mã SP' },
        { id: 'product_name', label: 'Tên SP' },
        { id: 'quantity', label: 'Số lượng' },
        { id: 'unit', label: 'Đơn vị' },
        { id: 'current_price_per_unit', label: 'Giá nhập (VNĐ)', isFormatNumber: true },
        { id: 'total_price', label: 'Tổng tiền (VNĐ)', isFormatNumber: true },
        { id: 'created_date', label: 'Ngày tạo' }
    ];

    const inventoryColumns = [
        { id: 'id', label: 'Mã SP' },
        { id: 'name', label: 'Tên SP' },
        { id: 'quantity', label: 'Số lượng' },
        { id: 'unit', label: 'Đơn vị' }
    ];

    const formAddNewInventory = useFormik({
        initialValues: {
            product_id: "",
            product_name: "",
            quantity: 0,
            unit: "",
            current_price_per_unit: 0,
            total_price: 0,
            created_date: ""
        },
        validationSchema: Yup.object({
            product_id: Yup.string().required('SP không được để trống'),
            quantity: Yup.number().min(1, 'Số lượng sản phẩm phải lớn hơn hoặc bằng 1'),
            unit: Yup.string().required('Đơn vị không được để trống'),
            current_price_per_unit: Yup.number().min(1, 'Giá nhập phải lớn hơn hoặc bằng 1'),
        }),
        onSubmit: async (order) => {
            order.created_date = moment(new Date()).format('DD/MM/YYYY - HH:mm:ss');
            dispatch(setInventoryDataAction(order));
            formAddNewInventory.resetForm({ values: formAddNewInventory.initialValues });
        },
    });

    const handleToggleAddForm = () => {
        setAddFormVisible(!isAddFormVisible);
    };

    const handleToggleHistory = () => {
        setHistoryVisible(!isHistoryVisible);
    };

    const handleToggleInventory = () => {
        setInventoryVisible(!isInventoryVisible);
    };

    const calcTotalPrice = () => {
        return formAddNewInventory.values.quantity * formAddNewInventory.values.current_price_per_unit;
    }

    useEffect(() => {
        if (!productsData) {
            dispatch(getAllProductsData());
        } else {
            setProductData(productsData);
        }
    }, [productsData]);

    useEffect(() => {
        if (!unitData) {
            dispatch(getAllUnitData());
        } else {
            setUnitsData(unitData);
        }
    }, [unitData]);

    useEffect(() => {
        if (!inventoryHistoryData) {
            dispatch(getAllHistoryInventoryDataAction());
        } else {
            setInventoryHistory(inventoryHistoryData)
        }
    }, [inventoryHistoryData]);

    useEffect(() => {
        if (!inventoryData) {
            dispatch(getAllInventoryDataAction());
        } else {
            setInventoriesData(inventoryData)
        }
    }, [inventoryData]);


    useEffect(() => {
        formAddNewInventory.setFieldValue("total_price", calcTotalPrice());
    }, [
        formAddNewInventory.values.quantity,
        formAddNewInventory.values.current_price_per_unit,
    ]);

    return (
        <>
            <Container fluid className="custom-container">
                <div className='orders-content'>
                    <h1>QUẢN LÝ KHO</h1>
                    <Button variant="outlined" onClick={handleToggleAddForm}>
                        Nhập Kho
                    </Button>
                    <Collapse in={isAddFormVisible}>
                        <div className='form-add'>
                            <form onSubmit={formAddNewInventory.handleSubmit}>
                                <Row className='form-field'>
                                    <Col md={2}>
                                        Chọn sản phẩm:
                                    </Col>
                                    <Col md={5}>
                                        <Select
                                            fullWidth
                                            labelId="product-select-label"
                                            id="product-select"
                                            name="product_id"
                                            value={formAddNewInventory.values.product_id}
                                            onChange={(e) => {
                                                formAddNewInventory.handleChange(e);
                                                formAddNewInventory.setFieldValue("product_name", productData.find(item => item.id == e.target.value).name);
                                            }}
                                            onBlur={formAddNewInventory.handleBlur}
                                            error={formAddNewInventory.touched.product_id && Boolean(formAddNewInventory.errors.product_id)}
                                        >
                                            {productData.map((product) => (
                                                <MenuItem key={product.id} value={product.id}>
                                                    {product.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Col>
                                </Row>
                                <Row className='form-field'>
                                    <Col md={2}>
                                        Số lượng:
                                    </Col>
                                    <Col md={5}>
                                        <TextField
                                            label="Số lượng"
                                            type='number'
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            name="quantity"
                                            value={formAddNewInventory.values.quantity}
                                            onChange={formAddNewInventory.handleChange}
                                            inputProps={{ min: 0, step: 0.1 }}
                                            error={formAddNewInventory.touched.quantity && Boolean(formAddNewInventory.errors.quantity)}
                                            helperText={formAddNewInventory.touched.quantity && formAddNewInventory.errors.quantity}
                                        />
                                    </Col>
                                </Row>
                                <Row className='form-field'>
                                    <Col md={2}>
                                        Đơn vị tính:
                                    </Col>
                                    <Col md={5}>
                                        <Select
                                            fullWidth
                                            labelId="product-select-label"
                                            id="product-select"
                                            name="unit"
                                            value={formAddNewInventory.values.unit}
                                            onChange={(e) => {
                                                formAddNewInventory.handleChange(e);
                                            }}
                                            onBlur={formAddNewInventory.handleBlur}
                                            error={formAddNewInventory.touched.unit && Boolean(formAddNewInventory.errors.unit)}
                                        >
                                            {unitsData.map((product) => (
                                                <MenuItem key={product.id} value={product.id}>
                                                    {product.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Col>
                                </Row>
                                <Row className='form-field'>
                                    <Col md={2}>
                                        {`Giá nhập (${formAddNewInventory.values.unit}):`}
                                    </Col>
                                    <Col md={5}>
                                        <TextField
                                            label="Giá nhập"
                                            type='number'
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            name="current_price_per_unit"
                                            value={formAddNewInventory.values.current_price_per_unit}
                                            onChange={formAddNewInventory.handleChange}
                                            inputProps={{ min: 0 }}
                                            error={formAddNewInventory.touched.current_price_per_unit && Boolean(formAddNewInventory.errors.current_price_per_unit)}
                                            helperText={formAddNewInventory.touched.current_price_per_unit && formAddNewInventory.errors.current_price_per_unit}
                                        />
                                    </Col>
                                </Row>
                                <Row className='form-field'>
                                    <Col md={2} className='total'>
                                        Tổng tiền:
                                    </Col>
                                    <Col md={5}>
                                        <TextField
                                            disabled
                                            type='number'
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            name="total_price"
                                            value={formAddNewInventory.values.total_price}
                                            onChange={formAddNewInventory.handleChange}
                                            inputProps={{ min: 0 }}
                                        />
                                    </Col>
                                </Row>
                                <Button
                                    className='btn-login m10 custom-color'
                                    variant="contained"
                                    type="submit">
                                    Nhập Kho
                                </Button>
                            </form>
                        </div>
                    </Collapse>
                    <Button variant="outlined" className='mt-20' onClick={handleToggleHistory}>
                        Lịch sử nhập kho
                    </Button>
                    <Collapse in={isHistoryVisible}>
                        <DynamicTable columns={historyColumns} dataList={inventoryHistory} />
                    </Collapse>
                    <Button variant="outlined" className='mt-20' onClick={handleToggleInventory}>
                        Tồn kho
                    </Button>
                    <Collapse in={isInventoryVisible}>
                        <DynamicTable columns={inventoryColumns} dataList={inventoriesData} />
                    </Collapse>
                </div>
            </Container>
        </>
    );
};

export default Inventory;
