import { getDataUserByUsername } from "../../services/api_services";
import { endRequest, startRequest } from "./loadingActions";

// Action Types
export const FETCH_DATA_REQUEST = 'FETCH_DATA_REQUEST';
export const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS';
export const FETCH_DATA_FAILURE = 'FETCH_DATA_FAILURE';

// Action Creators
export const fetchDataRequest = () => ({
    type: FETCH_DATA_REQUEST,
});

export const fetchDataSuccess = (data) => ({
    type: FETCH_DATA_SUCCESS,
    payload: data,
});

export const fetchDataFailure = (error) => ({
    type: FETCH_DATA_FAILURE,
    payload: error,
});

// Thunk
export const getUserInfoByUsername = (username) => {
    return async (dispatch) => {
        dispatch(startRequest());
        try {
            const userData = await getDataUserByUsername(username);
            dispatch(fetchDataSuccess(userData));
            dispatch(endRequest());
        } catch (error) {
            dispatch(fetchDataFailure(error));
            dispatch(endRequest());
        }
    };
};

