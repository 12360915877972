import CryptoJS from 'crypto-js';
import moment from 'moment';

export const encryptPassword = (password) => {
    const key = CryptoJS.enc.Utf8.parse('your-secret-key');
    const iv = CryptoJS.enc.Utf8.parse('your-initialization-vector');
    const encrypted = CryptoJS.AES.encrypt(password, key, { iv });
    return encrypted.toString();
}

export const decryptPassword = (password) => {
    const key = CryptoJS.enc.Utf8.parse('your-secret-key');
    const iv = CryptoJS.enc.Utf8.parse('your-initialization-vector');
    const encrypted = CryptoJS.AES.decrypt(password, key, { iv });
    return encrypted.toString();
}

export const generateID = (type, created_date) => {
    return type + moment(created_date, 'DD/MM/YYYY - HH:mm:ss').unix();
}

export const formatNumber = (value) => {
    return new Intl.NumberFormat('en-US').format(value);
};

export const formatNumberWithCurrency = (value) => {
    return new Intl.NumberFormat('en-US').format(value) + " VNĐ";
};

export const scrollTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth', // You can use 'auto' instead of 'smooth' for an instant scroll
    });
}