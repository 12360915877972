import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import './Admin.scss';
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { syncDataFromDevToProd, syncDataFromProdToDev, syncInventoryData, syncOrdersData } from '../../services/api_sync_services';
import { getAllInventoryData } from '../../services/api_services';
import { getAllOrdersDataAction } from '../../redux/actions/orderActions';
import { getAllInventoryDataAction } from '../../redux/actions/inventoryActions';

const AdminConsole = () => {
    const pubURL = process.env.PUBLIC_URL;
    const dispatch = useDispatch();

    const handleSyncOrdersData = async () => {
        await syncOrdersData();
    };

    const handleSyncProdToDevData = async () => {
        await syncDataFromProdToDev();
        dispatch(getAllInventoryDataAction());
        dispatch(getAllOrdersDataAction());
    };
    const handleSyncInventoryData = async () => {
        await syncInventoryData();
        dispatch(getAllInventoryDataAction());
        dispatch(getAllOrdersDataAction());
    };

    const handleSyncDevToProdData = async () => {
        await syncDataFromDevToProd();
        dispatch(getAllInventoryDataAction());
        dispatch(getAllOrdersDataAction());
    };

    return (
        <>
            <Container fluid className="custom-container">
                <div className='orders-content'>
                    <h1>Admin Console</h1>
                    <div>
                        <Button variant="outlined" className='mt-20' onClick={handleSyncOrdersData}>
                            Đồng bộ dữ liệu ĐH
                        </Button>
                    </div>
                    <div>
                        <Button variant="outlined" className='mt-20' onClick={handleSyncInventoryData}>
                            Đồng bộ dữ liệu Kho
                        </Button>
                    </div>
                    <div>
                        <Button variant="outlined" className='mt-20' onClick={handleSyncProdToDevData}>
                            Sync Data từ PROD xuống DEV
                        </Button>
                    </div>
                    <div>
                        <Button variant="outlined" className='mt-20' onClick={handleSyncDevToProdData}>
                            Sync Data từ DEV lên PROD
                        </Button>
                    </div>
                </div>
            </Container>
        </>
    );
};

export default AdminConsole;
