import { getAllHistoryInventoryData, getAllInventoryData, setInventoryHistoryData } from "../../services/api_services";
import { endRequest, setLoadingRequest, setLoadingSuccess, startRequest } from "./loadingActions";

// Action Types
export const SET_INVENTORY_DATA_REQUEST = 'SET_INVENTORY_DATA_REQUEST';
export const SET_INVENTORY_DATA_SUCCESS = 'SET_INVENTORY_DATA_SUCCESS';
export const SET_INVENTORY_DATA_FAILURE = 'SET_INVENTORY_DATA_FAILURE';

export const GET_HISTORY_INVENTORY_DATA_REQUEST = 'GET_HISTORY_INVENTORY_DATA_REQUEST';
export const GET_HISTORY_INVENTORY_DATA_SUCCESS = 'GET_HISTORY_INVENTORY_DATA_SUCCESS';
export const GET_HISTORY_INVENTORY_DATA_FAILURE = 'GET_HISTORY_INVENTORY_DATA_FAILURE';

export const GET_INVENTORY_DATA_REQUEST = 'GET_INVENTORY_DATA_REQUEST';
export const GET_INVENTORY_DATA_SUCCESS = 'GET_INVENTORY_DATA_SUCCESS';
export const GET_INVENTORY_DATA_FAILURE = 'GET_INVENTORY_DATA_FAILURE';

// Action Creators
export const setInventoryDataRequest = () => ({
    type: SET_INVENTORY_DATA_REQUEST,
});
export const setInventoryDataSuccess = () => ({
    type: SET_INVENTORY_DATA_SUCCESS,
});
export const setInventoryDataFailure = (error) => ({
    type: SET_INVENTORY_DATA_FAILURE,
    payload: error,
});

export const getHistoryInventoryDataRequest = () => ({
    type: GET_HISTORY_INVENTORY_DATA_REQUEST,
});
export const getHistoryInventoryDataSuccess = (data) => ({
    type: GET_HISTORY_INVENTORY_DATA_SUCCESS,
    payload: data
});
export const getHistoryInventoryDataFailure = (error) => ({
    type: GET_HISTORY_INVENTORY_DATA_FAILURE,
    payload: error,
});

export const getInventoryDataRequest = () => ({
    type: GET_INVENTORY_DATA_REQUEST,
});
export const getInventoryDataSuccess = (data) => ({
    type: GET_INVENTORY_DATA_SUCCESS,
    payload: data
});
export const getInventoryDataFailure = (error) => ({
    type: GET_INVENTORY_DATA_FAILURE,
    payload: error,
});

// Thunk
export const setInventoryDataAction = (data) => {
    return async (dispatch) => {
        dispatch(startRequest());
        try {
            await setInventoryHistoryData(data);
            dispatch(setInventoryDataSuccess());
            dispatch(getAllHistoryInventoryDataAction());
            dispatch(getAllInventoryDataAction());
            dispatch(endRequest());
        } catch (error) {
            dispatch(setInventoryDataFailure(error));
            dispatch(endRequest());
        }
    };
};

export const getAllHistoryInventoryDataAction = () => {
    return async (dispatch) => {
        dispatch(startRequest());
        try {
            const data = await getAllHistoryInventoryData();
            dispatch(getHistoryInventoryDataSuccess(data));
            dispatch(endRequest());
        } catch (error) {
            dispatch(getHistoryInventoryDataFailure(error));
            dispatch(endRequest());
        }
    };
};

export const getAllInventoryDataAction = () => {
    return async (dispatch) => {
        dispatch(startRequest());
        try {
        const data = await getAllInventoryData();
            dispatch(getInventoryDataSuccess(data));
            dispatch(endRequest());
        } catch (error) {
            dispatch(getInventoryDataFailure(error));
            dispatch(endRequest());
        }
    };
};