import { combineReducers } from 'redux';
import userDataReducer from './reducers/userReducer';
import productReducer from './reducers/productReducer';
import orderReducer from './reducers/orderReducer';
import inventoryReducer from './reducers/inventoryReducer';
import loadingReducer from './reducers/loadingReducer';

// Combine Reducers
const rootReducer = combineReducers({
    userData: userDataReducer,
    productsData: productReducer,
    ordersData: orderReducer,
    inventoryData: inventoryReducer,
    loading: loadingReducer
});

export default rootReducer;
